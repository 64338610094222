@media only screen and (max-width: 1000px) {
  .login_button {
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .login_link{
    display: none;
  }
}
